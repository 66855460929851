.bottomMenu {
  color: white;
  position: fixed;
  z-index: 100;
  bottom: 0px;
  left: 0;
  width: 100%;

  background-color: transparent;
  //background-image: url('/assets/back-base_menu_final.svg');
  //background-size: cover;
  //background-position: center;
  //background-repeat: no-repeat;
  //height: 88.3px;
  //transform: translate(0px, calc(100% - 30px));
  //position: absolute;

  //iPhone 14 pro max
  @media only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) {
    padding-bottom: 20px;
    .bottom__bg__svg{
      bottom: 15px !important;
    }
  }

  //iPhone 14 plus
  @media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
    padding-bottom: 20px;
    .bottom__bg__svg{
      bottom: 15px !important;
    }
  }

  //iPhone XR
  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
    padding-bottom: 20px;
    .bottom__bg__svg{
      bottom: 15px !important;
    }
    //padding-bottom: 35px;
  }

  //iPhone 14 pro
  @media only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) {
    padding-bottom: 20px;
    .bottom__bg__svg{
      bottom: 15px !important;
    }
  }

  //iPhone 14
  @media only screen and (device-width: 394px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
    padding-bottom: 20px;
    .bottom__bg__svg{
      bottom: 15px !important;
    }
  }
  
  //iPhone X
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    padding-bottom: 20px;
    .bottom__bg__svg{
      bottom: 15px !important;
    }
  }

  ul,
  ol {
    list-style-type: none;
    padding-left: 0;
  }

  ul {
    display: flex;
    margin: auto;
    max-width: 400px;

    @include media-breakpoint-up(lg) {
      //height: 100px;
      max-width: 650px;
    }
  }

  li {
    flex: 1 1 20%;
    text-align: center;
  }


  // .bottom__menu__bar {
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   bottom: 0;
  //   z-index: -1;
  //   background-image: url('/assets/back-base_menu_final.svg');
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   background-size: cover;

  //   @include media-breakpoint-up(lg) {
  //     //background-size: 170% auto;
  //   }

  //   //transform: translateX(-50%);
  //   //z-index: -1;
  //   //max-height: 112px;
  // }

  .bottom__bg{
    position: absolute;
    width: 100%;
    height: 48%;
    background-color: black;
    bottom: 0;
    z-index: -1;
  }

  .bottom__bg__svg{
    position: absolute;
    bottom: 0px;
    z-index: -1;
    width: 1300px;
    left: 50%;
    transform: translateX(-50%);
    @include media-breakpoint-up(lg){
      width: 1900px;
      bottom: 0%;
    }
  }
  .dumy__bottom_bg{
    position: absolute;
    bottom: 0%;
    z-index: -1;
    width: 100%;
    height: 20px;
    display: block;
    background-color: #1c1c1c;
    @include media-breakpoint-up(lg){
      display: none;
    }
  }

  .bottom__menu__bar2 {
    position: absolute;
    width: 100%;
    height: 10%;
    bottom: 0;
    //background-color: ;
    //z-index: -1;
    //max-height: 157px;
  }

}


.bottomMenu__li {
  transition: all 0.5s ease-out;

  &:hover,
  &:focus,
  &.selected {
    background-color: rgba(#000, 0.1);
  }
}

.bottomMenu__link {

  display: inline-block;
  color: white;
  text-align: center;
  padding: 4px 5px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  opacity: 0.7;

  //padding-bottom: 10px;
  //font-family: $family-header;

  * {
    position: relative;
    z-index: 100;
  }

  .icon {
    width: 32px;
    height: 32px;
    //@include bg-img();
    margin: auto;
    //transition: all 0.3s ease-out;
  }

  div {
    font-size: 9px;
    letter-spacing: 0.5px;
    line-height: 1;
    margin-top: 0.3rem;
    font-weight: 700;
  }

  &:hover,
  &:focus,
  &.selected {
    //transform: translate(0, -2px);
    color: white;
    text-decoration: none;
    opacity: 1;
  }

  @include media-breakpoint-up(lg) {
    padding: 15px 5px;

    .icon {
      width: 40px;
      height: 40px;

      &.icon__index__0 {
        //width: 40px;
        //height: 40px;
      }

      &.icon__index__1 {
        //width: 45px;
        //height: 45px;
        //margin-top: -8px;
      }

      &.icon__index__2 {
        //width: 55px;
        //height: 55px;
        //margin-top: -20px;
        //padding: 12px 60px;
      }

      &.icon__index__3 {
        //width: 45px;
        //height: 45px;
        //margin-top: -8px;
      }

      &.icon__index__4 {
        //width: 40px;
        //height: 40px;
      }
    }

    div {
      font-size: 13px;
      letter-spacing: 0.5px;
    }
  }
}

// .bottomMenu__link--2 {
//   padding: 12px 60px;
// }

.icon {
  img {
    width: 100%;
    height: 100%;
  }

  .normal {
    display: block;
  }

  .selected {
    display: none;
  }
}

.bottomMenu__link:hover .icon,
.bottomMenu__link.selected .icon {
  .normal {
    display: none;
  }

  .selected {
    display: block;
  }
}


//https://tympanus.net/Development/IconHoverEffects/#set-8