b,
strong {
  font-weight: $weight-bold;
}

a {
  color: $link-color;
  transition: $transition-base;

  &:hover,
  &:focus,
  &:active {
    //color: darken($primary-color, 5%);
    color: $color-light-gold;
    //text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5 {
  //font-family: $family-header;
  //font-weight: $weight-normal;
  //color: $header-color;
  line-height: 1.2;
  margin: 0.5em 0 0.3em;
}

h1,
h2 {
  font-family: $family-header;
  margin-top: 0px;
  font-weight: $weight-regular;
  //letter-spacing: 60px;
}

h1 {
  font-weight: $weight-bold;
  color: $color-dark-gold;
}

h2 {
  font-weight: $weight-bold;
  color: $color-dark-gold;
  margin-top: 0;
  margin-bottom: 0;
}

h3 {
  //font-weight: $weight-bold;
}

h4 {
  letter-spacing: 1px;
  //font-weight: $weight-bold;
  //color: $color-light-gold;
}

h5 {
  //font-weight: $weight-bold;
  color: $color-light-gold;
}

h6 {
  color: white;
}

// size
h1 {
  //font-size: rem(36, $font-size-mobile);
  font-size: rem(32, $font-size-mobile);
}

h2 {
  //font-size: rem(28, $font-size-mobile);
  //font-size: rem(26, $font-size-mobile);
  font-size: 2rem;
}

h3 {
  font-size: rem(24, $font-size-mobile);
}

h4 {
  font-size: rem(20, $font-size-mobile);
}

h5 {
  font-size: rem(14, $font-size-mobile);
  color: black;
}

h6 {
  font-size: rem(13, $font-size-mobile);
  color: black;
}

@include media-breakpoint-up(lg) {

  h1 {
    font-size: rem(64, $font-size-desktop);
  }

  h2 {
    font-size: rem(56, $font-size-desktop);
  }

  h3 {
    font-size: rem(48, $font-size-desktop);
  }

  h4 {
    font-size: rem(30, $font-size-desktop);
  }

  h5 {
    font-size: rem(22, $font-size-desktop);
  }

  h5 {
    font-size: rem(18, $font-size-desktop);
  }

}

// html editor
.text-tiny {
  font-size: 0.75rem;
}

.text-big {
  font-size: 1.5rem;
}