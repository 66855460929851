@import './mixins';
@import './variables';

// plugins
@import './bootstrap/bootstrap';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import '~react-day-picker/lib/style.css';
@import '~react-modal-video/scss/modal-video.scss';

//@import './fonts';
@import './base';
@import './sections';
@import './form';

// top
//@import './layout/hamburger';
//@import './layout/top';
@import './layout/top-navbar';

// bottom
@import './layout/bottomPanel';
@import './layout/bottomMenu';

@import './cms/cms-site';

// modules
@import './modules/breadcrumb';
@import './modules/rotatingBanner';
@import './modules/bannerSize';
@import './modules/pageTiles';
//@import './modules/blogTiles';
@import './modules/slider';
@import './modules/multiColumnPanel';

// components
@import '../components';

@import './site/index';

//aos
@import "~aos/src/sass/aos";

@media print {
  @import './print';
}

//banner-text-bar
section.banner-text-bar {
  .htmlPanel {
    p {
      margin-bottom: 0px;
      text-align: center;
    }
  }
}

.choose-file-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  label.choose-file-label {
    background-color: white;
    margin-bottom: 0px !important;
    //font-family: $family-button;
    padding: 0.3rem 0.5rem;
    border-radius: 0.25rem;
    font-weight: 600;
    //color: #444;
  }

  div.file__attach__text {
    margin-left: 1rem;
    //color: white;
  }
}

body:not(.cmsedit) {
  div.cms_item.desktop-ig-feed {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }

  div.cms_item.mobile-ig-feed {
    display: block;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

div.youtube__scroll__button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0px;
  max-width: 20px;
  z-index: 9;
  cursor: pointer;

  img {
    width: 100%;
  }
}

.youtubePlayer--full {
  @include media-breakpoint-up(xl) {
    // 1200px and up
    //height: calc(100vh - 2rem);
    height: 100vh;
  }
}

.common-footer {

  background-size: auto 100%;
  font-size: 0.9rem;

  &>div.container {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
  }

  .multiColumnPanel {
    max-width: none;

    .first__row {
      img {
        max-width: 85%;
      }
      .title img{
        width: 16px;
      }
    }

    &>div.container {
      max-width: none;
    }

    .container {
      margin-left: auto;
      margin-right: auto;
    }

    div.row>div {
      padding-left: 15px;
      padding-right: 15px;

      @include media-breakpoint-up(lg) {
        padding-left: 4rem;
        padding-right: 4rem;
      }

      section.contentPanel div.container {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }

  .multiColumnPanel section.contentPanel {
    background-color: transparent;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.full__panel__google__map__embed__con iframe {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}

div.reservation-embed iframe, div.reservation-iframe iframe{
  @include media-breakpoint-down(xs){
    height: 730px;
  }
}

section.contentPanel.our__menu__desktop{
  display: none;
  @include media-breakpoint-up(md){
    display: block;
  }
}

section.contentPanel.our__menu__mobile{
  display: block;
  @include media-breakpoint-up(md){
    display: none;
  }
}

.grecaptcha-badge { 
  visibility: hidden;
}

.pageTiles__new__01{
  .slick-dots{
    @include media-breakpoint-down(sm){
      top: 120px !important;
    }
  }
}